











import { Component, Mixins, Prop } from 'vue-property-decorator'

import { BeatFilterType, fetchBeats, fetchBeatsWithSearch } from '~/api/beats'
import EmbedBeatTable from '@embed/components/EmbedBeatTable.vue'
import ThePlayer from '@embed/components/ThePlayer.vue'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { fetchAmplifyPlayer } from '@embed/api/amplify-player'
import { updateCssVariable } from '~/utils/updateCssVariable'
import debounce from 'lodash.debounce'

@Component({ components: { EmbedBeatTable, ThePlayer } })
export default class HomePage extends Mixins(InfiniteLoadingSetState) {
  @Prop({ type: String }) id!: IAmplifyPlayer['id']

  beats: IBeat[] = []
  producer: IProducer
  amplifyPlayer: IAmplifyPlayer
  searchKeyword: string = ''

  makeSearchCall = debounce(async (component: HomePage, value: string) => {
    // if search is empty, we load the default list of beats
    if (value) component.beats = await fetchBeatsWithSearch(value, component.$route.params.producerSlug)
    else this.updateBeatsTable(null, 0)
  }, 500)

  get loadMoreEnabled() {
    return this.beats.length > 0 && !this.searchKeyword
  }

  async updateBeatsTable($state: any, offset: number) {
    if (!this.producer) return

    // call the store to update the beats
    const beats = await fetchBeats({ filterType: BeatFilterType.Producer, slug: this.producer.slug, limit: 5, offset })

    if (offset === 0) {
      this.beats = beats
      // we load the data for the first beat
      this.$store.dispatch('player/togglePlayStatus', {
        beats: beats,
        index: 0,
        doNotPlay: true
      })
    } else {
      this.beats.push(...beats)
    }
    // set loading state for vue-infinite-loading
    this.setLoadingStateAndUpdatePlayerQueue($state, beats)
  }

  onSearchKeywordChange(newValue: string) {
    this.searchKeyword = newValue
    this.makeSearchCall(this, newValue)
  }

  async mounted() {
    this.amplifyPlayer = await fetchAmplifyPlayer(this.id)
    this.producer = this.amplifyPlayer.producer
    // update css variables from js variables
    Object.keys(this.amplifyPlayer).forEach(it => {
      const key = it as keyof IAmplifyPlayer
      updateCssVariable(key, this.amplifyPlayer[key])
    })
    this.updateBeatsTable(null, 0)
  }
}
