import { render, staticRenderFns } from "./ThePlayer.vue?vue&type=template&id=7d6ae4d4&lang=pug&"
import script from "./ThePlayer.vue?vue&type=script&lang=ts&"
export * from "./ThePlayer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports