























import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

import EmbedBeatTableItem from '@embed/components/EmbedBeatTableItem.vue'
import InfiniteLoading from 'vue-infinite-loading'

@Component({ components: { EmbedBeatTableItem, InfiniteLoading } })
export default class EmbedBeatTable extends Vue {
    @Prop({ type: Array }) beatPlaylist: IBeat[]
    @Prop({ type: Boolean }) loadMoreEnabled: boolean

    searchKeyword: string = ''

	@Watch('searchKeyword')
    onSearchKeywordChange() {
        this.$emit('onSearchKeywordChange', this.searchKeyword)
    }

    infiniteHandler($state: any) {
        this.$emit('fetchMoreData', $state)
    }
}
