
























import { Prop, Component, Mixins } from 'vue-property-decorator'
import { BeatStore } from '~/store/beat'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { PlayerStore } from '~/store/player'

import { BeatAlreadyInCart } from '@/mixins/beat-already-in-cart'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { AudioFileType } from '~/const/audio-file-type'

@Component
export default class EmbedBeatTableItem extends Mixins(BeatAlreadyInCart, BeatImageOrProducerLogo) {
    @Prop({ type: Array }) beatPlaylist: IBeat[]
    @Prop({ type: Number }) index: number

    AudioFileType = AudioFileType
    ModalType = ModalType

    @PlayerStore.State('isAudioPaused') isAudioPaused: boolean
    @PlayerStore.Getter('beatPlaying') beatPlaying: IBeat

    get beat(): IBeat {
        return this.beatPlaylist[this.index]
    }

    get audioClasses() {
        return {
            isPlaying: !this.isAudioPaused && this.beat === this.beatPlaying,
            isPaused: this.isAudioPaused && this.beat === this.beatPlaying
        }
    }

    @BeatStore.Action('demoDownloadBeat') demoDownloadBeat: (beat: IBeat) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (IModalParams: IModalParams) => void

    togglePlayStatus() {
        // then we call the togglePlayStatus passing the right params
        this.$store.dispatch('player/togglePlayStatus', {
            beats: this.beatPlaylist,
            index: this.index,
            route: this.$route.fullPath
        })
    }
}
