


























import { Component, Mixins } from 'vue-property-decorator'
import { AbstractPlayer } from '~/mixins/abstract-player'
import { CartStore } from '~/store/cart'

@Component
export default class ThePlayer extends Mixins(AbstractPlayer) {
    @CartStore.State('cart') cart: ICartItem[]
}
